import LocalizedStrings from "react-localization";
import Config from "itchi_config";

let strings = new LocalizedStrings({
  en: {
    app_name: Config.app_name,
    log_out: "Log Out",
    back: "Back",
    course: "Course",
    go_to_course: "Go to Course",
    whats_included: "What's included",
    assessment: "Assessment",
    course_content: "Course Content",
    show_less: "Show Less",
    show_more: "Show More",
    quiz: "Quiz",
    previous: "Previous",
    next: "Next",
    questions: "Questions",
    to_pass: "To pass",
    or_higher: "or higher",
    start: "Start",
    submit: "Submit",
    try_again: "Try agian",
    incorrect: "Incorrect",
    uploading_quiz_result: "Uploading Quiz Result...",
    congratulation_you_passed: "Congratulation！You Passed!",
    grade_received: "Grade Received",
    loading: "Loading...",
    sign_in_hints: "Enter your email and password to sign in",
    dont_have_account: "Don't have an account?",
    sign_in: "Sign In",
    sign_out: "Sign Out",
    sign_up: "Sign Up",
    email: "Email",
    password: "Password",
    password_confirm: "Confirm Password",
    sign_up_hints: "Enter your email and password to register",
    i_agree_the: "  I agree the ",
    terms_and_conditions: "Terms and Conditions",
    already_have_account: "Already have an account?",
    question_multiple_choice: "Multiple Choice",
    password_not_match: "Confirm Password is not match",
    password_empty: "Password is empty",
    email_empty: "Email is empty",
    full_name: "Full Name (For certificate)",
    full_name_empty: "Full name is empty"
  },
  // zh_Hans:{

  // },
  zh_Hant: {
    app_name: Config.app_name,
    log_out: "登出",
    back: "返回",
    course: "課程",
    go_to_course: "前往課程",
    whats_included: "其中內容",
    assessment: "評估",
    course_content: "課程內容",
    show_less: "顯示較少",
    show_more: "顯示較多",
    quiz: "測驗",
    previous: "上一節",
    next: "下一節",
    questions: "問題",
    to_pass: "合格線為",
    or_higher: "或以上",
    start: "開始",
    submit: "提交",
    try_again: "再試一次",
    incorrect: "錯誤",
    uploading_quiz_result: "上存測驗結果中...",
    congratulation_you_passed: "恭喜你已通過測驗！",
    grade_received: "成績為",
    loading: "加載中...",
    sign_in_hints: "請輸入你的電郵和密碼以登入系統",
    dont_have_account: "沒有帳戶？",
    sign_in: "登入",
    sign_out: "登出",
    sign_up: "註冊",
    email: "電郵",
    password: "密碼",
    password_confirm: "確認密碼",
    sign_up_hints: "請輸入電郵和密碼以註冊",
    i_agree_the: "  我同意 ",
    terms_and_conditions: "條款和條件",
    already_have_account: "已經有帳號？",
    question_multiple_choice: "多選題",
    password_not_match: "確認密碼不相符。",
    password_empty: "請輸入密碼",
    email_empty: "請輸入電郵",
    full_name: "英文全名(顯示在證書上)",
    full_name_empty: "請輸入英文全名。"
  },
});

// set default language
strings.setLanguage("zh_Hant");

export default strings;
