import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import bgImage from "assets/images/Register.png";

import BookingCard from "examples/Cards/BookingCard";
import booking1 from "assets/images/products/product-1-min.jpg";

import firebase from "../../examples/connectionHandler/firebase";
import fetchAPI from "../../examples/connectionHandler/FetchAPI";

import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Grid } from "@mui/material";

import CircularProgress from "@mui/material/CircularProgress";
import { useLocation } from "react-router-dom";

import Loc from "localization";
import loadingBox from "components/loadingBox";
import MDButton from "components/MDButton";


// import { useLocation } from "react-router-dom";

function Homepage() {
  const { state } = useLocation();
  const { language_code } = state;
  // console.log(language_code);

  // For navigation path and data to be transfer
  const [pathToRedirect, setRedirect] = React.useState("");
  const [itemToEdit, setItemToEdit] = React.useState(null);

  const [course, set_course] = React.useState("");
  const [courseName, set_courseName] = React.useState("");
  const [course_Description, set_course_Description] = React.useState("");
  const [language, set_Language] = React.useState(language_code);
  const [passRate, set_PassRate] = React.useState(0);

  const [isSignedIn, setSignedIn] = React.useState(false);
  const [isCheckedSignIn, set_isCheckedSignIn] = React.useState(false);
  const [data_adminInfo, setDate_adminInfo] = React.useState(null);

  const [isLoading, set_isLoading] = React.useState(true);

  // Launch navigation
  let navigate = useNavigate();
  const check_redirect = () => {
    if (pathToRedirect !== "") {
      if (itemToEdit !== null) {
        return navigate(pathToRedirect, { state: itemToEdit });
      } else {
        return navigate(pathToRedirect);
      }
    } else {
      return null;
    }
  };

  // Start navigate after setstate is completed
  useEffect(() => {
    if (itemToEdit !== null) {
      console.log("Selected Navigation", pathToRedirect);
      check_redirect();
    }

    if (language_code != null)
    {
      set_Language(language_code);
      console.log("language_code: " + language_code);
    }
  }, [itemToEdit]);

  // Check login or not
  function add_authListener() {
    // console.log("add_authListener called in Admin");
    return firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        set_isLoading(true);
        // User is signed in.
        console.log("Checking, signed in");
        setSignedIn(true);
        get_courseData();
      } else {
        // User is signed out.
        console.log("NOT signed in");
        setRedirect("/authentication/sign-in/illustration");
        setItemToEdit("");
        setSignedIn(false);
        set_isCheckedSignIn(true);
      }
    });
  }

  // Called when checked login
  function get_courseData() {
    // get course info
    const body = {
      language_code: language,
    };
    // console.log(body);
    fetchAPI.do_fetch("post", "course/get_setting", body).then(
      (res) => {
        // set_isLoading(false);
        // console.log("this is the result:", res);
        // console.log(temp_courseName);
        set_PassRate(res.data.pass_rate);
        // console.log(res.data.pass_rate);

        set_course(res.data);
      },
      (error) => {
        firebase
          .auth()
          .signOut()
          .then(function () {
            console.log("Sign-out successful.", error);
            setSignedIn(false);
            set_isCheckedSignIn(true);
            // Sign-out successful.
          })
          .catch(function (error) {
            console.log("Sign-out fail, ", error);
            setSignedIn(false);
            set_isCheckedSignIn(true);
            // An error happened.
          });
      }
    );
    fetchAPI.do_fetch("post", "course/get_course_intro_list", body).then(
      (res) => {
        set_isLoading(false);
        // console.log(
        //   "this is the result of course intro:",
        //   res.data.course_intro_list
        // );
        setCourseArray(res.data.course_intro_list);
      },
      (error) => {
        firebase
          .auth()
          .signOut()
          .then(function () {
            console.log("Sign-out successful.");
            setSignedIn(false);
            set_isCheckedSignIn(true);
            // Sign-out successful.
          })
          .catch(function (error) {
            console.log("Sign-out fail, ", error);
            setSignedIn(false);
            set_isCheckedSignIn(true);
            // An error happened.
          });
      }
    );
  }

  // Check Signin or not
  useEffect(() => {
    set_isCheckedSignIn(false);
    const subscription_auth = add_authListener();
    return function cleanup() {
      subscription_auth();
    };
  }, []);

  useEffect(() => {
    Loc.setLanguage(language === "en" ? "en" : "zh_Hant");
  }, [language]);

  const handle_Logout = () => {
    firebase
      .auth()
      .signOut()
      .then(function () {
        setRedirect("/authentication/sign-in/illustration");
        setItemToEdit("");
      });
  };

  const [courseArray, setCourseArray] = React.useState([]);

  // Render method of Course Card
  const renderCourseCard = courseArray.map((course, key) => {
    course.passRate = passRate;
    course.language_code = language;
    // console.log(course);
    return (
      <Grid
        item
        display="flex"
        minHeight="701px"
        minWidth="609px"
        width="609px"
        style={
          {
            // backgroundColor: "pink"
          }
        }
        mx="55px"
        my="50px"
        key={key}
      >
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            setRedirect("/introduction");
            setItemToEdit(course);
          }}
        >
          <BookingCard
            image={course.banner || null}
            title={language === "en" ? course.title_eng : course.title}
            description={
              language === "en" ? course.description_eng : course.description
            }
            action={null}
          />
        </div>
      </Grid>
    );
  });

  return isLoading === true ? loadingBox : (
    <div>
      <MDBox bgColor="#FFFFFF" minWidth="800px">
        <MDBox
          display="flex"
          justifyContent="right"
          maxWidth="100%"
          p={1}
        >
          <MDBox
            color="white"
            // bgColor="info"
            variant="gradient"
            borderRadius="lg"
            // shadow="lg"
            opacity={1}
            width={150}
            display="flex"
            justifyContent="center"
            alignItems="center"
            mr={2}
          >
            <MDButton size="small" color="info" variant="text" onClick={() => {
                  Loc.setLanguage("zh_Hant");
                  set_Language("zh-hk");
                }}>
              中
            </MDButton>
            <MDTypography color="info"> | </MDTypography>
            <MDButton size="small" color="info" variant="text" onClick={() => {
                  Loc.setLanguage("en");
                  set_Language("en");
                }}>
              Eng
            </MDButton>
          </MDBox>
          

          <MDButton size="small" color="info" variant="gradient" onClick={() => handle_Logout()}>
          {Loc.sign_out}
          </MDButton>          
        </MDBox>


        <MDBox
          sx={{
            backgroundImage: `url(${bgImage})`,
            backgroundPosition: "center",
            backgroundRepeat: 0,
            backgroundSize: "cover",
            minHeight: 381,
            maxWidth: "100%",
            backgroundColoer: "#D8D8D8",
            opacity: 1,
          }}
        ></MDBox>

        <MDBox
          bgColor="#FFFFFF"
          pt="41px"
          pl="15%"
          pr="15%"
          justifyContent="center"
        >
          <MDTypography
            fontFamily="Roboto"
            fontSize="48px"
            fontWeight="medium"
            textAlign="left"
            style={{ wordWrap: "break-word" }}
          >
            {language === "en" ? course.corse_name_eng : course.corse_name}
          </MDTypography>
          <MDTypography
            style={{ wordWrap: "break-word" }}
            py="50px"
            fontFamily="Roboto"
            fontSize="36px"
            fontWeight="regular"
            textAlign="left"
          >
            {language === "en"
              ? course.course_description_eng
              : course.course_description}
          </MDTypography>
        </MDBox>

        <MDBox
          // bgColor="cyan"
          pt="30px"
          pl="12%"
          // pr="15%"
          pb="140px"
          justifyContent="center"
          display="flex"
        >
          <Grid
            container
            // style={{ backgroundColor: "red" }}
            width="100%"
          >
            {renderCourseCard}
          </Grid>
        </MDBox>
      </MDBox>
    </div>
  );
}

export default Homepage;
