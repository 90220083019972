// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
var firebase = require("firebase/app");

// Add the Firebase products that you want to use
require("firebase/auth");
require("firebase/firestore");
require("firebase/storage");

const firebaseConfig = {
  apiKey: "AIzaSyDmTGJE58s9pgH9PDTr8necrzfMf3_4_Es",
  authDomain: "marine-ambassador.firebaseapp.com",
  projectId: "marine-ambassador",
  storageBucket: "marine-ambassador.appspot.com",
  messagingSenderId: "377146823649",
  appId: "1:377146823649:web:2d71e8ce940c37f02e78a0",
  measurementId: "G-MFT9FJ12M1",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;
